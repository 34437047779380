.App {
  padding-top: 45px;
}

#error-mesage-container {
  color: red;
}

.sample-item {
  cursor: pointer;
}
